<template>
    <div class="sidebar">
        <div class="profile">
            <img src="../assets/Untitled-2-12.svg" width="30" height="30">
            <h3>ADMIN</h3>
            <p>Board</p>
        </div>
        <ul>
            <li>
                <a href="#" class="active">
                    <span class="icon"><i class="fas fa-home"></i></span>
                    <span class="item"> <router-link to="/" custom v-slot="{ navigate }">
                            <span @click="navigate" @keypress.enter="navigate" role="link">Home</span>
                        </router-link></span>
                </a>
            </li>
            <li>
                <a href="/" class="active">
                    <span class="icon"><i class="fas fa-desktop"></i></span>
                    <span class="item">
                        <router-link to="/work" custom v-slot="{ navigate }">
                            <span @click="navigate" @keypress.enter="navigate" role="link">My Dashboard</span>
                        </router-link>
                    </span>
                </a>
            </li>
            <li>
                <!-- <span class="icon"><i class="fas fa-user-friends"></i></span>
                    <router-link to="/employee" class="item">Pracownicy</router-link> -->
                <a href="/employee" class="active">
                    <span class="icon"><i class="fas fa-user-friends"></i></span>
                    <span class="item"> <router-link to="/employee" custom v-slot="{ navigate }">
                            <span @click="navigate" @keypress.enter="navigate" role="link">Pracownicy</span>
                        </router-link></span>
                </a>
            </li>
            <li>
                <a href="/compa" class="active">
                    <span class="icon"><i class="fas fa-tachometer-alt"></i></span>
                    <span class="item"> <router-link to="/compa" custom v-slot="{ navigate }">
                            <span @click="navigate" @keypress.enter="navigate" role="link">Firmy</span>
                        </router-link></span>
                </a>
            </li>
            <li>
                <a href="/" class="active">
                    <span class="icon"><i class="fas fa-database"></i></span>
                    <span class="item">Strony</span>
                </a>
            </li>
            <li>
                <a href="/" class="active">
                    <span class="icon"><i class="fas fa-chart-line"></i></span>
                    <span class="item">
                        <router-link to="/course" custom v-slot="{ navigate }">
                            <span @click="navigate" @keypress.enter="navigate" role="link">Szkolenia</span>
                        </router-link></span>
                </a>
            </li>
            <li>
                <a href="/" class="active">
                    <span class="icon"><i class="fas fa-user-shield"></i></span>
                    <span class="item">Użytkownicy</span>
                </a>
            </li>
            <li>
                <a href="/" class="active">
                    <span class="icon"><i class="fas fa-cog"></i></span>
                    <span class="item">Ustawienia</span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {

}
</script>
<style>
* {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, 'Open Sans', sans-serif;
}

/* body {
    background: #f5f6fa;
} */

.wrapper .sidebar {
    /* background: black; */
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding: 20px 0;
    transition: all 0.5s ease;
}

.wrapper .sidebar .profile {
    margin-bottom: 30px;
    text-align: center;
}

.wrapper .sidebar .profile img {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
}

.wrapper .sidebar .profile h3 {
    color: black;
    margin: 10px 0 5px;
}

.wrapper .sidebar .profile p {
    color: #1124FF;
    font-size: 14px;
}

.wrapper .sidebar ul li a {
    display: block;
    padding: 13px 30px;
    border: 1px solid black;
    color: rgb(241, 237, 237);
    font-size: 16px;
    position: relative;
}

.wrapper .sidebar ul li a .icon {
    color: #dee4ec;
    width: 30px;
    display: inline-block;
}



.wrapper .sidebar ul li a:hover,
.wrapper .sidebar ul li a.active {
    color: black;
    background: white;
    /* border-right: 2px solid rgb(5, 68, 104); */
}

.wrapper .sidebar ul li a:hover .icon,
.wrapper .sidebar ul li a.active .icon {
    color: black;
}

.wrapper .sidebar ul li a:hover:before,
.wrapper .sidebar ul li a.active:before {
    display: block;
}

.wrapper .section {
    width: calc(100% - 225px);
    margin-left: 225px;
    transition: all 0.5s ease;
}

.wrapper .section .top_navbar {
    background: rgb(7, 105, 185);
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    padding: 0 30px;
}

.wrapper .section .top_navbar .hamburger a {
    font-size: 28px;
    color: #f4fbff;
}

.wrapper .section .top_navbar .hamburger a:hover {
    color: #a2ecff;
}



body.active .wrapper .sidebar {
    left: -225px;
}

body.active .wrapper .section {
    margin-left: 0;
    width: 100%;
}
</style>