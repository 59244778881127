<template>
    <div class="wrapper">
        <!-- <div class="section">
            <div class="top_navbar">
                <div class="hamburger">
                    <a href="#">
                        <i class="fas fa-bars"></i>
                    </a>
                </div>
            </div>
        </div> -->
        <AdminSideBar></AdminSideBar>
    </div>
</template>

<script>
import AdminSideBar from './AdminSideBar.vue';

export default {
    setup() {
        window.addEventListener("click", function () {
            //   const hamburger = document.querySelector(".hamburger");
            //   const body = document.querySelector("body");
            //   body.classList.toggle("active");
            //   hamburger.classList.toggle("active");
        });
    },
    components: { AdminSideBar }
}


// var hamburger = document.querySelector(".hamburger");
// 	hamburger.addEventListener("click", function(){
// 		document.querySelector("body").classList.toggle("active");
// 	})
// import UserService from "../services/user.service";
// export default {
//   name: "Admin",
//   data() {
//     return {
//       content: "",
//     };
//   },
//   mounted() {
//     UserService.getAdminBoard().then(
//       (response) => {
//         this.content = response.data;
//       },
//       (error) => {
//         this.content =
//           (error.response &&
//             error.response.data &&
//             error.response.data.message) ||
//           error.message ||
//           error.toString();
//       }
//     );
//   },
// };
</script>

